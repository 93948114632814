<template>
    <v-card flat v-if="!editStatus">
      <v-toolbar flat color="info">
            <v-toolbar-title>
                Heroes
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="4" offset-md=8 class="text-center text-md-right">
                    <v-btn color="secondary" outlined @click="addHero()">
                        <v-icon left>fas fa-plus-circle</v-icon>
                        Add Hero
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense v-for="hero in getHeroes" :key="hero.name" class="subtitle-1 entries" align="center">
                <v-col cols="2" class="text-center">
                    <v-img height="30" width="30" :src="getImgUrl(hero.name)" lazy-src="@/assets/favicon.png"></v-img>
                </v-col>
                <v-col cols="4">{{hero.name}}</v-col>
                <v-col cols="5" class="text-center">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" :color="attColor[hero.attribute]">{{attIcons[hero.attribute]}}</v-icon>
                        </template>
                        <span>{{hero.attribute}}</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="1">
                    <v-btn text small icon color="secondary" @click="editHero(hero)">
                        <v-icon>fas fa-edit</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    <v-card v-else>
        <v-toolbar flat color="info">
            <v-toolbar-title>
                Add New Hero
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12" md="6" offset-md="3">
                    <v-text-field v-model="hero.name" label="Hero Name:" color="secondary"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" offset-md="3">
                    <v-select v-model="hero.attribute" label="Hero Name:" color="secondary" :items="attList"></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="text-center">
                    <v-btn outlined color="red" @click="close()">
                        <v-icon left>fas fa-times</v-icon>
                        Cancel
                    </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                    <v-btn outlined color="secondary" @click="submitHero()" :disabled="!hero.name && !hero.attribute">
                        <v-icon left>fas fa-check</v-icon>
                        {{hero.new ? "Submit New Hero" : "Update Hero"}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data(){
        return{
            attColor: {
                Agility         : "green",
                Intelligence    : "blue",
                Strength        : "red",
                Universal       : "purple"
            },
            attIcons: {
                Agility         : "fab fa-google-wallet",
                Intelligence    : "fas fa-magic",
                Strength        : "far fa-hand-rock",
                Universal       : "fas fa-stroopwafel"
            },
            attList             : [
                "Agility", "Intelligence", "Strength", "Universal"
            ],
            editStatus          : false,
            hero             : {
                attribute   : "",
                id          : "",
                name        : "",
                new         : true
            }
        }
    },
    methods: {
        addHero(){
            this.editStatus         = true
        },
        close(){
            this.editStatus         = false
            this.hero.attribute     = ""
            this.hero.id            = ""
            this.hero.name          = ""
            this.hero.new           = true
        },
        editHero(heroData){
            this.editStatus         = true
            this.hero.attribute     = heroData.attribute
            this.hero.id            = heroData.id
            this.hero.name          = heroData.name
            this.hero.new           = false
        },
        getImgUrl(name){
            let convertedName =  name.toLowerCase().replaceAll(' ','_').replace("'","")
            let doesFileExist  = function() {
                var xhr = new XMLHttpRequest();
                xhr.open('HEAD', `/heroes/${convertedName}.jpg`, false);
                xhr.send();
                
                if (xhr.status == "404") {
                    return false;
                } else {
                    return true;
                }
            }
            if (doesFileExist() == true){
                return `/heroes/${convertedName}.jpg`
            }
            else {
                return `/heroes/dota.jpg`
            }
        },
        submitHero(){
            if (this.hero.new){
                this.$store.dispatch('createHero', this.hero)
            }
            else {
                this.$store.dispatch('updateHero', this.hero)
            }
        }
    },
    computed: {
        getHeroes(){
            return this.$store.getters.getHeroes.sort((a,b) => {
                if (a.name > b.name){
                    return 1
                }
                else {
                    return -1
                }
            })
        }
    }
}
</script>

<style scoped>
.entries:nth-child(odd){
    background: #d7dae5;
}
</style>